import { useEffect, useMemo, useState } from 'react';
import Validation from '../../../Constants/Validation';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  fetchCouponData,
  removeCoupon,
} from '../../../redux/features/Coupon/couponSlice';

import { BASE_URL_SANDBOX, USED_LP_POINTS } from '../../../Constants/Config';
import axios from 'axios';

const CouponTipSectionLogic = ({ merchantId }) => {
  const dispatch = useDispatch();

  const [orderSummaryData, setOrderSummaryData] = useState();
  const { filterCartData } = Validation();
  const location = useLocation();
  const [cart, setCart] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [storeproductData, setStoreProductData] = useState();
  const [singleStoreData, setSingleStoreData] = useState();
  const lp_sc_user_data = useSelector(
    (state) => state?.lpscPayment?.LpscPaymentData,
  );

  const [discountAmt, setdiscountAmt] = useState(0);
  const [totalTaxForLPEarned, setTotalTaxForLPEarned] = useState(0);
  const [totalTaxForLPApplied, setTotalTaxForLPApplied] = useState(0);
  const [percentageDeducLoy, setpercentageDeducLoy] = useState(0);
  const [percentageDeducCoupon, setpercentageDeducCoupon] = useState(0);
  const [amountDeducCoupon, setamountDeducCoupon] = useState(0);
  const [varientData, setVarientData] = useState([]);
  const [cartData, setCartAllData] = useState([]);
  const cartDataState = useSelector((state) => state?.cart?.cartData);
  const storeProductDataState = useSelector((state) => state?.storeProductData);
  const storeDataState = useSelector((state) => state?.singleStores);
  const VarientDataState = useSelector((state) => state?.variantCart);
  const taxDetailData = useSelector((state) => state?.taxDetail?.taxDetailData);
  const [taxValues, settaxValues] = useState(null);
  const AuthUserState = useSelector((state) => state?.authUser?.UserData);
  const [usedLoyaltyPointApiUserData, setusedLoyaltyPointApiUserData] =
    useState('');
  const cartProductDetails = useSelector(
    (state) => state.cartProducts.cartProductDetails,
  );
  const authUserData = useSelector((state) => state.authUser);

  // useEffect(() => {
  //   console.log('taxValues: ', taxValues);
  // }, [taxValues]);

  const [values, setValues] = useState({
    coupon: '',
    loyaltyPoint: '',
    tip: '',
    tipcustom: '',
    storeCredit: '',
    usedLoyaltyPoint: '',
    usedStoreCredit: '',
    tipRemoved: false,
    errors: {
      coupon: '',
      loyaltyPoint:
        parseFloat(lp_sc_user_data?.loyalty_program?.min_points_redemption) >
        parseFloat(lp_sc_user_data?.customer_lp_point?.cus_loyality_points)
          ? 'You do not have enough points to use'
          : '',
      disableLoyaltyPoint: false,
      storeCredit: '',
      tip: '',
      tipcustom: '',
    },
    cursorposition: {
      coupon: 0,
      loyaltyPoint: 0,
      tip: 0,
      tipcustom: 0,
      storeCredit: 0,
    },
  });

  useEffect(() => {
    setCart(cartDataState);
  }, [cartDataState]);

  useEffect(() => {
    if (
      !storeProductDataState.loading &&
      !VarientDataState.loading &&
      !storeDataState.loading &&
      storeDataState &&
      storeDataState.storesData &&
      storeDataState.storesData.store_data
    ) {
      setStoreProductData(storeProductDataState.storeProductData);
      setVarientData(VarientDataState.variantCartData);
      setSingleStoreData(storeDataState.storesData.store_data);
    }
  }, [
    storeProductDataState.loading,
    storeDataState.loading,
    storeDataState.storesData,
    VarientDataState.loading,
    VarientDataState.variantCartData,
  ]);

  useEffect(() => {
    if (
      cartDataState &&
      VarientDataState?.variantCartData &&
      cartProductDetails
    ) {
      const data = filterCartData(
        cartDataState,
        varientData,
        cartProductDetails,
      );
      setCartAllData(data);
    }
  }, [storeproductData, cartDataState, cart, varientData]);

  const calculateTotalPrice = async (cartData) => {
    const total = await cartData.reduce((acc, product) => {
      return parseFloat(acc) + parseFloat(product.price);
    }, 0);
    return setTotalPrice(total.toFixed(2));
  };

  const taxCalculationLogic = (cart, taxes) => {
    const taxData = {};
    // console.log('-------------------------');
    // console.log('cart: ', cart);
    // console.log('taxes: ', taxes);

    // tax calculation logic
    cart.forEach((item) => {
      taxes.forEach((tax) => {
        if (item?.taxesAplicable && item?.taxesAplicable?.includes(tax.id)) {
          const deductCouponDiscount = (itemPrice) => {
            const isCouponApplied =
              parseFloat(percentageDeducCoupon) > 0
                ? parseFloat(percentageDeducCoupon)
                : 0;
            const a = itemPrice - (itemPrice * isCouponApplied) / 100;
            return a;
          };

          const deductLoyaltyPointsDiscount = (itemPrice) => {
            const a = deductCouponDiscount(itemPrice);
            const isLPApplied =
              parseFloat(percentageDeducLoy) > 0
                ? parseFloat(percentageDeducLoy)
                : 0;
            const b = a - (a * isLPApplied) / 100;

            return b;
          };

          const newItemPrice =
            item.product.use_point === '1'
              ? deductLoyaltyPointsDiscount(item.price)
              : deductCouponDiscount(item.price);

          const taxAmount = (percent) =>
            (parseFloat(percent) * parseFloat(newItemPrice)) / 100;

          if (taxData[tax.title]) {
            taxData[tax.title] = (
              parseFloat(taxData[tax.title]) +
              parseFloat(taxAmount(tax.percent))
            ).toFixed(2);
          } else {
            taxData[tax.title] = parseFloat(taxAmount(tax.percent)).toFixed(2);
          }
        }
      });
    });

    // console.log('calculationLogic Tax data: ', taxData);
    // const convenienceFeeAdjustedTax = addingConvFeeTax(taxes, taxData);
    // console.log('convenienceFeeAdjustedTax: ', convenienceFeeAdjustedTax);
    // settaxValues(convenienceFeeAdjustedTax);

    // console.log('taxes: ', taxData);
    settaxValues(taxData);
  };

  useEffect(() => {
    // console.log('percentageDeducLoy: ', percentageDeducLoy);
    // console.log('percentageDeducCoupon: ', percentageDeducCoupon);
    // console.log('values.loyaltyPoint: ', values.loyaltyPoint);
    // console.log('values.usedLoyaltyPoint: ', values.usedLoyaltyPoint);
    if (cartData && taxDetailData) {
      calculateTotalPrice(cartData);
      taxCalculationLogic(cartData, taxDetailData);
      // if (values.loyaltyPoint && values.usedLoyaltyPoint) {
      //   // this will update the tax values state, so if LP is applied will call this
      //   calculateTaxAfterLPApplied(cartData, taxDetailData);
      //   return;
      // }

      // incase LP is not applied then will call this & update Tax Values state
      // CalcuLateTaxes(cartData, taxDetailData);
    }
  }, [
    cartData,
    percentageDeducLoy,
    percentageDeducCoupon,
    values.loyaltyPoint,
    values.usedLoyaltyPoint,
    taxDetailData,
  ]);

  const lpPointsCalculation = (cart, taxes) => {
    const deductCouponDiscount = (itemPrice) => {
      const isCouponApplied =
        parseFloat(percentageDeducCoupon) > 0
          ? parseFloat(percentageDeducCoupon)
          : 0;
      const a = itemPrice - (itemPrice * isCouponApplied) / 100;
      return a;
    };

    const deductLoyaltyPointsDiscount = (itemPrice) => {
      const a = deductCouponDiscount(itemPrice);
      const isLPApplied =
        parseFloat(percentageDeducLoy) > 0 ? parseFloat(percentageDeducLoy) : 0;
      const b = a - (a * isLPApplied) / 100;
      return b;
    };

    const newCart = cart.map((item) => {
      let newItem = { ...item };
      const newItemPrice =
        item.product?.use_point === '1'
          ? deductLoyaltyPointsDiscount(item.price)
          : deductCouponDiscount(item.price);

      item?.taxesAplicable &&
        item?.taxesAplicable?.forEach((itemTax) => {
          const taxFound = taxes?.find((tax) => tax.id === itemTax);
          if (taxFound) {
            if (newItem.totalTaxAmount) {
              newItem.totalTaxAmount +=
                (newItemPrice * parseFloat(taxFound.percent)) / 100;
            } else {
              newItem.totalTaxAmount =
                (newItemPrice * parseFloat(taxFound.percent)) / 100;
            }
          }
        });

      return { ...newItem, newItemPrice };
    });

    // console.log('newCart: ', newCart);

    const getTotal = (finalizedCart, type) => {
      let total = finalizedCart
        .filter((cartItem) => cartItem.product.earn_point === type)
        .reduce(
          (acc, curr) =>
            (acc += curr.newItemPrice + (curr.totalTaxAmount || 0)),
          0,
        );
      return total;
    };

    const earnPointsAmount = getTotal(newCart, '1');
    const nonEarnPointsAmount = getTotal(newCart, '0');
    // console.log('earnPointsAmount: ', earnPointsAmount);
    // console.log('nonEarnPointsAmount: ', nonEarnPointsAmount);

    const storeCredit =
      values.usedStoreCredit && parseFloat(values.usedStoreCredit) > 0
        ? parseFloat(values.usedStoreCredit)
        : 0;
    // console.log('storeCredit: ', storeCredit);

    // let scPointsPending =
    //   nonEarnPointsAmount > storeCredit
    //     ? nonEarnPointsAmount - storeCredit
    //     : storeCredit - nonEarnPointsAmount;
    let earnPoints = 0;
    // console.log('1 scPointsPending: ', scPointsPending);

    // if (scPointsPending > nonEarnPointsAmount && storeCredit > 0) {
    //   earnPoints = earnPointsAmount - scPointsPending;
    //   console.log('2 earnPoints: ', earnPoints);
    // } else {
    //   earnPoints = earnPointsAmount;
    //   console.log('3 earnPoints: ', earnPoints);
    // }

    if (storeCredit >= nonEarnPointsAmount) {
      let amountToDeduct = storeCredit - nonEarnPointsAmount;
      // console.log('amountToDeduct: ', amountToDeduct);
      earnPoints = earnPointsAmount - amountToDeduct;
      // console.log('1 earnPoints: ', earnPoints);
    } else {
      earnPoints = earnPointsAmount;
      // console.log('2 earnPoints: ', earnPoints);
    }

    // sc - 100
    // earn amt - 82.68
    // non earn amt - 86.71

    /*
    1) sc is greater than non earn amount or not..
    2) if it is greater than.. i will have to minus from earn amount also.. 
    3) else earn amount will be clear as earnPointsAmount. 
    5) if sc is more than non earn amt, then first minus non earn amt with SC and then again minus that with earn amount. 
    */

    const usePoints = newCart
      .filter((item) => item.product.use_point === '1')
      .reduce(
        (acc, curr) => (acc += curr.newItemPrice + (curr.totalTaxAmount || 0)),
        0,
      );

    // console.log('final LP use points amount: ', usePoints);
    // console.log('final LP earn points amount: ', earnPoints);

    return {
      usePoints: parseFloat(usePoints) > 0 ? parseFloat(usePoints) : 0,
      earnPoints: parseFloat(earnPoints) > 0 ? parseFloat(earnPoints) : 0,
    };
  };

  useEffect(() => {
    // const earnPointProducts = cartData.filter(
    //   (item) => item?.product?.earn_point === '1',
    // );

    // const earnPointAmount = getPointsAmount(
    //   earnPointProducts,
    //   'earnPoints',
    //   cartData,
    // );

    // const usePointProducts = cartData.filter(
    //   (item) => item?.product?.use_point === '1',
    // );

    // const usePointAmount = values.loyaltyPoint
    //   ? getPointsAmount(usePointProducts, 'usePoints', cartData)
    //   : 0;

    const points = lpPointsCalculation(cartData, taxDetailData);
    // console.log('HEHE: ', points);

    const dataObj = {
      cartItems: Array.isArray(cartData) && cartData.length,
      cartProductSum: parseFloat(totalPrice),
      singleStoreData: singleStoreData,
      taxData: taxValues,
      taxDetailApiData: taxDetailData,
      lp_sc_data: {
        enable_loyalty: lp_sc_user_data?.loyalty_program?.enable_loyalty,
        store_credit_amt: values.usedStoreCredit,
        spend_loyality_amount: usedLoyaltyPointApiUserData?.used_amt,
        spend_loyality_points: usedLoyaltyPointApiUserData?.used_points,
        // total_lp_apply_amt: totalLPApplyAmount.toFixed(2),
        // total_lp_earn_amt: totalLPEarnAmount.toFixed(2),
        // total_lp_apply_amt: usePointAmount.toFixed(2),
        // total_lp_earn_amt: parseFloat(earnPointAmount).toFixed(2),
        total_lp_apply_amt: parseFloat(points?.usePoints)?.toFixed(2),
        total_lp_earn_amt: parseFloat(points?.earnPoints)?.toFixed(2),
      },
      discountAmt: discountAmt,
      percentageDeducCoupon: percentageDeducCoupon ?? 0,
      percentageDeducLoy: percentageDeducLoy ?? 0,
    };

    setOrderSummaryData(dataObj);
  }, [
    cartData,
    totalPrice,
    singleStoreData,
    taxValues,
    percentageDeducLoy,
    values.usedStoreCredit,
    values.loyaltyPoint,
    discountAmt,
    totalTaxForLPApplied,
    totalTaxForLPEarned,
    // additional dependencies....
    amountDeducCoupon,
    lp_sc_user_data?.loyalty_program?.enable_loyalty,
    percentageDeducCoupon,
    taxDetailData,
    usedLoyaltyPointApiUserData?.used_amt,
    usedLoyaltyPointApiUserData?.used_points,
  ]);

  // const getTax = (singleCartPrice, singleTaxPercent, percentageDeducted) => {
  //   const a =
  //     (parseFloat(singleCartPrice) * parseFloat(percentageDeducted)) / 100;
  //   const b = ((singleCartPrice - a) * parseFloat(singleTaxPercent)) / 100;
  //   return b;
  // };

  // const baseTax = (taxInfo, cart, type, from) => {
  //   const taxData = {};
  //   // console.log('taxInfo: ', taxInfo);
  //   // console.log('cart: ', cart);

  //   for (let index = 0; index < taxInfo?.length; index++) {
  //     const singleTax = taxInfo[index];
  //     for (let i = 0; i < cart.length; i++) {
  //       const singleCart = cart[i];

  //       // tax calculation for LP applied
  //       if (
  //         values.loyaltyPoint &&
  //         values.usedLoyaltyPoint &&
  //         singleCart.product.use_point === '1' &&
  //         singleCart?.taxesAplicable?.includes(singleTax.id)
  //         //  &&
  //         // !bool
  //       ) {
  //         const productsWithUsePoint = cart.filter(
  //           (product) => product.product.use_point === '1',
  //         );
  //         const totalPrice = productsWithUsePoint.reduce(
  //           (acc, curr) => (acc += curr.price),
  //           0,
  //         );
  //         // console.log('totalPrice: ', totalPrice);
  //         const percentageDeducted = parseFloat(
  //           (values.loyaltyPoint / parseFloat(totalPrice)) * 100,
  //         );
  //         // console.log('percentageDeducted: ', percentageDeducted);
  //         const tax = getTax(
  //           singleCart.price,
  //           singleTax.percent,
  //           percentageDeducted,
  //         );
  //         // console.log('tax getTax: ', tax);
  //         if (taxData.hasOwnProperty(singleTax.title)) {
  //           taxData[singleTax.title] = taxData[singleTax.title] + tax;
  //         } else {
  //           taxData[singleTax.title] = tax;
  //         }
  //       } else {
  //         // for normal tax calculation
  //         if (singleCart?.taxesAplicable?.includes(singleTax.id)) {
  //           if (taxData.hasOwnProperty(singleTax.title)) {
  //             taxData[singleTax.title] =
  //               taxData[singleTax.title] +
  //               (parseFloat(singleCart.price) * parseFloat(singleTax.percent)) /
  //                 100;
  //           } else {
  //             taxData[singleTax.title] =
  //               (parseFloat(singleCart.price) * parseFloat(singleTax.percent)) /
  //               100;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   // console.log('base tax: ', taxData);
  //   return taxData;
  // };

  // const addingConvFeeTax = (taxInfo, totalTaxCopy) => {
  //   const defaultTaxObject = taxInfo?.find((tax) => tax.title === 'DefaultTax');

  //   if (defaultTaxObject) {
  //     const defaultTaxAmount = totalTaxCopy['DefaultTax']
  //       ? totalTaxCopy['DefaultTax']
  //       : 0;

  //     const confFee =
  //       parseFloat(storeDataState?.storesData?.store_data?.getConvFee) || 0;
  //     const confFeeTaxVal =
  //       confFee * (parseFloat(defaultTaxObject.percent) / 100);

  //     const newDefaultValue = defaultTaxAmount + confFeeTaxVal;

  //     if (newDefaultValue) {
  //       totalTaxCopy['DefaultTax'] = parseFloat(newDefaultValue);
  //     } else {
  //       totalTaxCopy['DefaultTax'] = parseFloat(confFeeTaxVal);
  //     }
  //   }

  //   return totalTaxCopy;
  // };

  // calculating Tax Amount
  // const finalTaxAmount = (taxInfo, cart, type, from) => {
  //   let totalTax = {};

  //   totalTax = baseTax(taxInfo, cart, type, from);

  //   let totalTaxCopy = { ...totalTax };

  //   // after getting the base tax, decreasing LP applied amount percent or Coupon discount percent from each tax
  //   if (from !== 'getPointsAmount') {
  //     if (
  //       parseFloat(percentageDeducCoupon) > 0 &&
  //       parseFloat(percentageDeducLoy) > 0 &&
  //       values.loyaltyPoint &&
  //       values.usedLoyaltyPoint
  //     ) {
  //       for (let taxType in totalTaxCopy) {
  //         let newPercentDeduct =
  //           parseFloat(percentageDeducLoy) + parseFloat(percentageDeducCoupon);

  //         totalTaxCopy[taxType] -=
  //           (totalTaxCopy[taxType] * newPercentDeduct) / 100;
  //       }
  //     } else if (percentageDeducCoupon > 0) {
  //       for (let taxType in totalTaxCopy) {
  //         totalTaxCopy[taxType] -=
  //           (totalTaxCopy[taxType] * percentageDeducCoupon) / 100;
  //       }
  //     } else if (
  //       percentageDeducLoy > 0 &&
  //       values.loyaltyPoint &&
  //       values.usedLoyaltyPoint
  //     ) {
  //       for (let taxType in totalTaxCopy) {
  //         totalTaxCopy[taxType] -=
  //           (totalTaxCopy[taxType] * percentageDeducLoy) / 100;
  //       }
  //     }
  //   }

  //   let totalTaxAmount = 0;

  //   // Loop through each tax type in the tax object again to sum the amounts
  //   for (let taxType in totalTaxCopy) {
  //     // Accumulate the tax amount
  //     totalTaxAmount += totalTaxCopy[taxType];
  //   }

  //   // const taxData = addingConvFeeTax(taxInfo, totalTaxCopy);
  //   let taxData = { ...totalTaxCopy };
  //   return { totalTaxAmount, taxData };
  // };

  // const CalcuLateTaxes = (cartInfo, taxInfo) => {
  //   // console.log('cartInfo: ', cartInfo);
  //   // console.log('taxinfo: ', taxInfo);

  //   // earn - 0 & use - 0
  //   const basicProducts = cartInfo.filter(
  //     (item) =>
  //       item?.product?.earn_point === '0' && item?.product?.use_point === '0',
  //   );
  //   const totalTaxForBasicProducts = finalTaxAmount(
  //     taxInfo,
  //     basicProducts,
  //     'totalTaxForBasicProducts',
  //   );

  //   // earn - 1 & use - 1
  //   const cartItemsWithBothPointsActive = cartInfo.filter(
  //     (item) =>
  //       item?.product?.use_point === '1' && item?.product?.earn_point === '1',
  //   );
  //   const totalTaxForBothPointsActive = finalTaxAmount(
  //     taxInfo,
  //     cartItemsWithBothPointsActive,
  //     'totalTaxForBothPointsActive',
  //   );

  //   // earn - 1 & use - 0
  //   const cartItemsWithOnlyActiveEarnPoint = cartInfo.filter(
  //     (item) =>
  //       item?.product?.earn_point === '1' && item?.product?.use_point === '0',
  //   );
  //   const totalTaxOnlyForEarnedLP = finalTaxAmount(
  //     taxInfo,
  //     cartItemsWithOnlyActiveEarnPoint,
  //     'totalTaxOnlyForEarnedLP',
  //   );

  //   // earn - 0 & use - 1
  //   const cartItemsWithOnlyActiveUsePoint = cartInfo.filter(
  //     (item) =>
  //       item?.product?.use_point === '1' && item?.product?.earn_point === '0',
  //   );
  //   const totalTaxOnlyForAppliedLP = finalTaxAmount(
  //     taxInfo,
  //     cartItemsWithOnlyActiveUsePoint,
  //     'totalTaxOnlyForAppliedLP',
  //   );

  //   // earn - 1 & use - 0 or 1
  //   const cartItemsWithActiveEarnPoint = cartInfo.filter(
  //     (item) => item?.product?.earn_point === '1',
  //   );
  //   const totalTaxForEarnedLP = finalTaxAmount(
  //     taxInfo,
  //     cartItemsWithActiveEarnPoint,
  //     'totalTaxForEarnedLP',
  //   );

  //   // earn - 0 or 1 & use - 1
  //   const cartItemsWithActiveUsePoint = cartInfo.filter(
  //     (item) => item?.product?.use_point === '1',
  //   );
  //   const totalTaxForAppliedLP = finalTaxAmount(
  //     taxInfo,
  //     cartItemsWithActiveUsePoint,
  //     'totalTaxForAppliedLP',
  //   );

  //   const earnPointTax = { ...totalTaxOnlyForEarnedLP.taxData } || {};
  //   const usePointTax = { ...totalTaxOnlyForAppliedLP.taxData } || {};
  //   const basicProductsTax = { ...totalTaxForBasicProducts.taxData } || {};
  //   const bothPointsActiveTax =
  //     { ...totalTaxForBothPointsActive.taxData } || {};

  //   setTotalTaxForLPEarned(totalTaxForEarnedLP.totalTaxAmount);
  //   setTotalTaxForLPApplied(totalTaxForAppliedLP.totalTaxAmount);

  //   const finalTaxValues = {};

  //   for (const key in earnPointTax) {
  //     // If the key is also in object 'b', add the values
  //     if (usePointTax[key]) {
  //       finalTaxValues[key] = earnPointTax[key] + usePointTax[key];
  //     } else {
  //       // If the key is only in object 'a', keep the value as it is
  //       finalTaxValues[key] = earnPointTax[key];
  //     }
  //   }

  //   for (const key in usePointTax) {
  //     // If the key was not already added from object 'a', add it to 'res'
  //     if (!finalTaxValues[key]) {
  //       finalTaxValues[key] = usePointTax[key];
  //     }
  //   }

  //   for (const key in basicProductsTax) {
  //     // If the key is also in object 'b', add the values
  //     if (finalTaxValues[key]) {
  //       finalTaxValues[key] = finalTaxValues[key] + basicProductsTax[key];
  //     } else {
  //       // If the key is only in object 'a', keep the value as it is
  //       finalTaxValues[key] = basicProductsTax[key];
  //     }
  //   }

  //   for (const key in bothPointsActiveTax) {
  //     // If the key is also in object 'b', add the values
  //     if (finalTaxValues[key]) {
  //       finalTaxValues[key] = finalTaxValues[key] + bothPointsActiveTax[key];
  //     } else {
  //       // If the key is only in object 'a', keep the value as it is
  //       finalTaxValues[key] = bothPointsActiveTax[key];
  //     }
  //   }

  //   const taxData = addingConvFeeTax(taxInfo, finalTaxValues);
  //   settaxValues(taxData);
  // };

  // const calculateTaxAfterLPApplied = (cart, taxDetails) => {
  //   let usePointProductsCart = cart.filter(
  //     (item) => item?.product?.use_point === '1',
  //   );
  //   let nonUsePointProductsCart = cart.filter(
  //     (item) => item?.product?.use_point === '0',
  //   );

  //   // if coupon is applied, then
  //   // 1) 10% coupon is applied lets say..
  //   // 2) product 1 price is $50 then its 10% will be $5 thus discount price will be $45
  //   // 3) now on $45 LP discount will be applied.
  //   if (percentageDeducCoupon > 0) {
  //     const priceToDeduct = (price) => (price * percentageDeducCoupon) / 100;
  //     const deductCouponDiscount = (data) => {
  //       const temp = data.map((item) => {
  //         const newItem = {
  //           ...item,
  //           price: item.price - priceToDeduct(item.price),
  //         };
  //         return newItem;
  //       });

  //       return temp;
  //     };
  //     usePointProductsCart = deductCouponDiscount(usePointProductsCart);
  //     nonUsePointProductsCart = deductCouponDiscount(nonUsePointProductsCart);
  //   }

  //   const cartItemsWithActiveEarnPoint = cartData?.filter(
  //     (item) => item?.product?.earn_point === '1',
  //   );

  //   const cartItemsWithActiveUsePoint = cartData?.filter(
  //     (item) => item?.product?.use_point === '1',
  //   );

  //   // setting total tax for LP Earned & LP Applied
  //   const totalTaxForEarnedLP = finalTaxAmount(
  //     taxDetailData,
  //     cartItemsWithActiveEarnPoint,
  //     'totalTaxForEarnedLP',
  //   );

  //   const totalTaxForAppliedLP = finalTaxAmount(
  //     taxDetailData,
  //     cartItemsWithActiveUsePoint,
  //     'totalTaxForAppliedLP',
  //   );

  //   setTotalTaxForLPEarned(totalTaxForEarnedLP.totalTaxAmount);
  //   setTotalTaxForLPApplied(totalTaxForAppliedLP.totalTaxAmount);

  //   const totalPriceOfUsePoint = usePointProductsCart.reduce((acc, product) => {
  //     return parseFloat(acc) + parseFloat(product.price);
  //   }, 0);

  //   const percentageToDeduct = parseFloat(
  //     (values.loyaltyPoint / parseFloat(totalPriceOfUsePoint)) * 100,
  //   );

  //   let usePointProductsTax = {};
  //   let nonUsePointProductsTax = {};

  //   for (let index = 0; index < taxDetails?.length; index++) {
  //     const singleTax = taxDetails[index];
  //     // for - products whose use point is 1
  //     for (let i = 0; i < usePointProductsCart.length; i++) {
  //       const singleCart = usePointProductsCart[i];
  //       if (singleCart?.taxesAplicable?.includes(singleTax.id)) {
  //         // deducting LP applied amount from Products
  //         const deductionAmount =
  //           (parseFloat(singleCart.price) * parseFloat(percentageToDeduct)) /
  //           100;
  //         const productPrice = parseFloat(singleCart.price) - deductionAmount;

  //         if (usePointProductsTax.hasOwnProperty(singleTax.title)) {
  //           usePointProductsTax[singleTax.title] =
  //             usePointProductsTax[singleTax.title] +
  //             (parseFloat(productPrice) * parseFloat(singleTax.percent)) / 100;
  //         } else {
  //           usePointProductsTax[singleTax.title] =
  //             (parseFloat(productPrice) * parseFloat(singleTax.percent)) / 100;
  //         }
  //       }
  //     }

  //     // for - products whose use point is 0
  //     for (let idx = 0; idx < nonUsePointProductsCart.length; idx++) {
  //       const singleCart = nonUsePointProductsCart[idx];
  //       if (singleCart?.taxesAplicable?.includes(singleTax.id)) {
  //         if (nonUsePointProductsTax.hasOwnProperty(singleTax.title)) {
  //           nonUsePointProductsTax[singleTax.title] =
  //             nonUsePointProductsTax[singleTax.title] +
  //             (parseFloat(singleCart.price) * parseFloat(singleTax.percent)) /
  //               100;
  //         } else {
  //           nonUsePointProductsTax[singleTax.title] =
  //             (parseFloat(singleCart.price) * parseFloat(singleTax.percent)) /
  //             100;
  //         }
  //       }
  //     }
  //   }

  //   const finalTaxValues = {};

  //   for (const key in usePointProductsTax) {
  //     // If the key is also in object 'b', add the values
  //     if (nonUsePointProductsTax[key]) {
  //       finalTaxValues[key] =
  //         usePointProductsTax[key] + nonUsePointProductsTax[key];
  //     } else {
  //       // If the key is only in object 'a', keep the value as it is
  //       finalTaxValues[key] = usePointProductsTax[key];
  //     }
  //   }

  //   // Iterate through the keys in object 'b'
  //   for (const key in nonUsePointProductsTax) {
  //     // If the key was not already added from object 'a', add it to 'res'
  //     if (!finalTaxValues[key]) {
  //       finalTaxValues[key] = nonUsePointProductsTax[key];
  //     }
  //   }

  //   const taxData = addingConvFeeTax(taxDetails, finalTaxValues);
  //   settaxValues(taxData);
  // };

  const discountPrice = (price) =>
    price - (price * (percentageDeducCoupon ? percentageDeducCoupon : 0)) / 100;

  // const getPointsAmount = (cart, type, myCart) => {
  //   let totalPriceOfProducts = 0;
  //   let totalPriceForEarnPointProducts = 0;

  //   const lpDiscountPercent = (price) => {
  //     const percentAmount = (values.loyaltyPoint / parseFloat(price)) * 100;
  //     return percentAmount;
  //   };

  //   const newCart = cart.map((product) => {
  //     let newPrice = 0;
  //     if (percentageDeducCoupon && values.loyaltyPoint) {
  //       const productDiscountPrice = discountPrice(product.price);
  //       const totalPrice = cart?.reduce(
  //         (acc, curr) => (acc += discountPrice(curr.price)),
  //         0,
  //       );
  //       if (type === 'earnPoints') {
  //         totalPriceForEarnPointProducts = totalPrice;
  //       }

  //       newPrice = productDiscountPrice;

  //       if (type === 'earnPoints' && product.product.use_point === '0') {
  //         totalPriceOfProducts = totalPrice;
  //       } else {
  //         const lpDiscountToApply = lpDiscountPercent(totalPrice);
  //         const newTotalPrice = cart.reduce((acc, curr) => {
  //           if (curr.use_point === '0') return (acc += 0);
  //           const discountedPrice = discountPrice(curr.price);
  //           const deductionAmount = (discountedPrice * lpDiscountToApply) / 100;
  //           const productPrice = discountedPrice - deductionAmount;
  //           return (acc += productPrice);
  //         }, 0);
  //         totalPriceOfProducts = newTotalPrice;
  //       }
  //     } else if (values.loyaltyPoint && !percentageDeducCoupon) {
  //       const totalPrice = cart?.reduce((acc, curr) => (acc += curr.price), 0);
  //       // console.log('in get points - ', totalPrice);

  //       if (type === 'earnPoints') {
  //         totalPriceForEarnPointProducts = totalPrice;
  //       }

  //       newPrice = product.price;

  //       if (type === 'earnPoints' && product.product.use_point === '0') {
  //         totalPriceOfProducts = totalPrice;
  //       } else {
  //         const lpDiscountToApply = lpDiscountPercent(totalPrice);
  //         const newTotalPrice = cart.reduce((acc, curr) => {
  //           const a = lpDiscountToApply * curr.price;
  //           const b = a / 100;
  //           const c = curr.price - b;
  //           return (acc += c);
  //         }, 0);
  //         totalPriceOfProducts = newTotalPrice;
  //       }
  //     } else if (percentageDeducCoupon && !values.loyaltyPoint) {
  //       const productDiscountPrice = discountPrice(product.price);
  //       newPrice = productDiscountPrice;
  //       totalPriceOfProducts = totalPriceOfProducts + productDiscountPrice;
  //       if (type === 'earnPoints') {
  //         totalPriceForEarnPointProducts += productDiscountPrice;
  //       }
  //     } else {
  //       newPrice = product.price;
  //       totalPriceOfProducts = totalPriceOfProducts + product.price;
  //       if (type === 'earnPoints') {
  //         totalPriceForEarnPointProducts += product.price;
  //       }
  //     }

  //     return { ...product, price: newPrice };
  //   });

  //   const tax = finalTaxAmount(taxDetailData, newCart, type, 'getPointsAmount');

  //   const points =
  //     type === 'earnPoints'
  //       ? totalPriceForEarnPointProducts + tax.totalTaxAmount
  //       : totalPriceOfProducts + tax.totalTaxAmount;

  //   return points;
  // };

  const { validateCoupon, getCurrentDateTime } = Validation();
  const [couponData, setcouponData] = useState('');
  const couponDataState = useSelector((state) => state.coupon);

  useEffect(() => {
    if (couponDataState && !couponDataState.loading) {
      if (couponDataState?.errorMessage !== '') {
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
          errors: {
            ...previousState.errors,
            coupon: couponDataState.errorMessage,
          },
        }));
      } else if (
        parseFloat(couponDataState?.couponData?.min_amount) >
        parseFloat(totalPrice)
      ) {
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
          errors: {
            ...previousState.errors,
            coupon: `Minimum order amount should be $${parseFloat(
              couponDataState?.couponData?.min_amount,
            ).toFixed(2)}`,
          },
        }));
      } else {
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
          errors: {
            ...previousState.errors,
            coupon: '',
          },
        }));

        // flag 0 - Percent Coupon & flag - 1 Amount coupon
        if (couponDataState?.couponData?.flag === '0') {
          // const couponAppliedAmt =
          //   parseFloat(totalPrice) -
          //   parseFloat(
          //     (parseFloat(totalPrice) *
          //       parseFloat(couponDataState?.couponData?.discount)) /
          //       100,
          //   );
          // console.log('couponAppliedAmt: ', couponAppliedAmt);

          // const couponAppliedPercent =
          //   100 - (couponAppliedAmt / parseFloat(totalPrice)) * 100;
          // console.log('couponAppliedPercent: ', couponAppliedPercent);
          // setpercentageDeducCoupon(couponAppliedPercent);
          // setamountDeducCoupon(parseFloat(totalPrice) - couponAppliedAmt);

          // ------------------- New Code
          const maximumDiscountValue =
            parseFloat(couponDataState?.couponData?.maximum_discount) > 0
              ? parseFloat(couponDataState?.couponData?.maximum_discount)
              : 0;

          const currentDiscountValue = parseFloat(
            (parseFloat(totalPrice) *
              parseFloat(couponDataState?.couponData?.discount)) /
              100,
          );

          // console.log('currentDiscountValue: ', currentDiscountValue);
          // console.log('maximumDiscountValue: ', maximumDiscountValue);

          if (
            currentDiscountValue > maximumDiscountValue &&
            maximumDiscountValue > 0
          ) {
            // dont allow to set the Main Percent keep according to the Maximum discount
            // console.log('AS PER MAX DISCOUNT....');
            const newPercentageDiscount =
              (parseFloat(maximumDiscountValue) / parseFloat(totalPrice)) * 100;
            setpercentageDeducCoupon(newPercentageDiscount);

            // console.log('inside 1 -------------------------');
            const deductCouponAmount =
              (parseFloat(totalPrice) * parseFloat(newPercentageDiscount)) /
              100;
            // console.log('newPercentageDiscount: ', newPercentageDiscount);
            // console.log('deductCouponAmount: ', deductCouponAmount);

            setamountDeducCoupon(parseFloat(deductCouponAmount));
          } else {
            // console.log('inside 2 -------------------------');
            const discountAmount =
              parseFloat(totalPrice) - currentDiscountValue;
            const couponAppliedPercent =
              100 - (discountAmount / parseFloat(totalPrice)) * 100;
            // console.log('heheheh couponAppliedPercent: ', couponAppliedPercent);
            setpercentageDeducCoupon(couponAppliedPercent);

            const deductCouponAmount =
              (parseFloat(totalPrice) * parseFloat(couponAppliedPercent)) / 100;
            // console.log('deductCouponAmount: ', deductCouponAmount);
            setamountDeducCoupon(parseFloat(deductCouponAmount));
          }
        } else {
          const couponAppliedAmt =
            parseFloat(totalPrice) -
            parseFloat(couponDataState?.couponData?.discount);

          const couponAppliedPercent =
            100 - (couponAppliedAmt / parseFloat(totalPrice)) * 100;
          setpercentageDeducCoupon(couponAppliedPercent);
          setamountDeducCoupon(parseFloat(totalPrice) - couponAppliedAmt);
        }

        // debugger;

        setcouponData(couponDataState.couponData);
      }
    }
  }, [couponDataState, totalPrice]);

  const customerId = useMemo(() => {
    return authUserData.isAuthenticated &&
      authUserData.UserData &&
      authUserData.UserData.id
      ? authUserData.UserData.id
      : '';
  }, [authUserData]);

  const handleApplyCoupon = (e) => {
    if (values.usedLoyaltyPoint !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          ...previousState.errors,
          coupon: 'Please remove loyalty points for using a coupon code',
        },
      }));
    }

    if (values.usedStoreCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          coupon: 'Please remove store credit for using a coupon code',
        },
      }));
    }

    if (values.usedLoyaltyPoint !== '' && values.usedStoreCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          ...previousState.errors,
          coupon:
            'Please remove loyalty point and store credit points to add coupon code',
          storeCredit: '',
        },
      }));
    }

    // console.log('hehe values: ', values);

    if (values.usedLoyaltyPoint === '' && values.usedStoreCredit === '') {
      const current_date_time = getCurrentDateTime();
      if (values.errors.coupon === '') {
        const data = {
          coupon: values.coupon,
          merchant_id: merchantId,
          current_date_time: current_date_time,
          customer_id: customerId,
        };
        dispatch(fetchCouponData(data));
        setValues((previousState) => ({
          ...previousState,
          coupon: '',
        }));
      }
    }
  };

  const handleRemoveCoupon = () => {
    dispatch(removeCoupon(''));
    setcouponData('');
    setValues((previousState) => ({
      ...previousState,
      coupon: '',
    }));
    setpercentageDeducCoupon(0);
  };

  const handleAddingTip = (mytip) => {
    setValues((previousState) => {
      return {
        ...previousState,
        tip: mytip,
        tipRemoved:
          mytip === '' &&
          !previousState.tipRemoved &&
          previousState.tipcustom === ''
            ? true
            : false,
      };
    });
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    const { errors, cursorposition } = values;

    const fieldname = event.target.name;
    let fieldValue =
      fieldname === 'coupon'
        ? event.target.value.toUpperCase()
        : fieldname === 'tipcustom'
        ? event.target.value
            .replace(/[^\d.]/g, '')
            .replace(/^(\d*\.)(.*)\./, '$1$2')
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2')
        : event.target.value;

    const newCursorPosition = event.target.selectionStart;

    switch (fieldname) {
      case 'coupon':
        fieldValue.toUpperCase();
        cursorposition.coupon = newCursorPosition;
        validateCoupon(fieldValue, errors);
        break;
      case 'tip':
        cursorposition.coupon = newCursorPosition;
        validateCoupon(fieldValue, errors);
        break;
      case 'tipcustom':
        if (values.tipcustom === '') {
          values.errors.tipcustom = 'Please enter tip amount';
        } else {
          let inputStr = fieldValue.replace(/\D/g, ''); // Remove all non-digit characters for clean processing

          if (inputStr === '') {
            fieldValue = '0.00'; // Reset to default value if input is empty
          } else {
            // Remove leading zeros for clean numeric string
            inputStr = inputStr.replace(/^0+/, '');

            if (inputStr.length === 0) {
              fieldValue = '0.00';
            } else if (inputStr.length === 1) {
              fieldValue = '0.0' + inputStr;
            } else if (inputStr.length === 2) {
              fieldValue = '0.' + inputStr;
            } else {
              fieldValue =
                inputStr.slice(0, inputStr.length - 2) +
                '.' +
                inputStr.slice(-2);
            }
          }
        }
        cursorposition.tipcustom = event.target.selectionStart;
        break;

      default:
        break;
    }

    setValues((previousState) => ({
      errors,
      ...previousState,
      [fieldname]: fieldValue,
    }));
  };

  const handleStoreCredit = (event, grandtotal, storecredit, mytipamt) => {
    event.preventDefault();

    const { errors, cursorposition } = values;

    const fieldname = event.target.name;
    let fieldValue =
      fieldname === 'coupon'
        ? event.target.value.toUpperCase()
        : fieldname === 'tipcustom'
        ? event.target.value
            .replace(/[^\d.]/g, '')
            .replace(/^(\d*\.)(.*)\./, '$1$2')
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2')
        : fieldname === 'storeCredit'
        ? event.target.value
            // Remove extra dots and ensure only one dot exists at most
            .replace(/[^\d.]/g, '') // Allow digits and dots only
            .replace(/^(\d*\.)(.*)\./, '$1$2') // Remove extra dots
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2') // Remove extra dots after the decimal point
        : event.target.value;

    const newCursorPosition = event.target.selectionStart;

    switch (fieldname) {
      case 'storeCredit':
        cursorposition.storeCredit = newCursorPosition;
        // --------------------------------------===
        let inputStr = fieldValue.replace(/\D/g, '');
        inputStr = inputStr.replace(/^0+/, '');

        if (inputStr.length === 0) {
          // fieldValue = "0.00";
        } else if (inputStr.length === 1) {
          fieldValue = '0.0' + inputStr;
        } else if (inputStr.length === 2) {
          fieldValue = '0.' + inputStr;
        } else {
          fieldValue =
            inputStr.slice(0, inputStr.length - 2) + '.' + inputStr.slice(-2);
        }
        if (
          parseFloat(fieldValue) > parseFloat(grandtotal - mytipamt).toFixed(2)
        ) {
          errors.storeCredit = `Store Credit can't be greater than $${parseFloat(
            grandtotal - mytipamt,
          ).toFixed(2)}`;
        } else if (fieldValue === '') {
          errors.storeCredit = '';
        } else if (parseFloat(fieldValue) > storecredit) {
          errors.storeCredit = "Credit can't be greater than your Store Credit";
        } else if (parseFloat(fieldValue) === 0) {
          errors.storeCredit = "Credit can't be 0.";
        } else {
          errors.storeCredit = '';
        }
        break;
      default:
        break;
    }

    setValues((previousState) => ({
      ...previousState,
      errors,
      [fieldname]: fieldValue,
    }));
    if (fieldValue === '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          loyaltyPoint: '',
        },
      }));
    }
  };

  const handleInputLoyaltyPoint = (
    event,
    subtotal,
    loyaltyPoint,
    couponDiscount,
    setCouponValue,
  ) => {
    event.preventDefault();

    const { errors, cursorposition } = values;

    const fieldname = event.target.name;
    let fieldValue =
      fieldname === 'loyaltyPoint'
        ? event.target.value
            // Remove extra dots and ensure only one dot exists at most
            .replace(/[^\d.]/g, '') // Allow digits and dots only
            .replace(/^(\d*\.)(.*)\./, '$1$2') // Remove extra dots
            .replace(/^(\d*\.\d*)(.*)\./, '$1$2') // Remove extra dots after the decimal point
        : event.target.value;

    const newCursorPosition = event.target.selectionStart;

    switch (fieldname) {
      case 'loyaltyPoint':
        cursorposition.loyaltyPoint = newCursorPosition;
        let inputStr = fieldValue.replace(/\D/g, '');
        inputStr = inputStr.replace(/^0+/, '');

        if (inputStr.length === 0) {
          // fieldValue = "0.00";
        } else if (inputStr.length === 1) {
          fieldValue = '0.0' + inputStr;
        } else if (inputStr.length === 2) {
          fieldValue = '0.' + inputStr;
        } else {
          fieldValue =
            inputStr.slice(0, inputStr.length - 2) + '.' + inputStr.slice(-2);
        }

        const newSubTotal = cartData
          .filter((item) => item.product.use_point === '1')
          .reduce((acc, curr) => {
            const couponPercentage = parseFloat(percentageDeducCoupon)
              ? parseFloat(percentageDeducCoupon)
              : 0;
            const newprice = curr.price - (curr.price * couponPercentage) / 100;
            return (acc = acc + newprice);
          }, 0);

        const total =
          parseFloat(newSubTotal) > 0 ? parseFloat(newSubTotal).toFixed(2) : 0;

        const eligibleToApplyLP = cartData.some(
          (item) => item?.product?.use_point === '1',
        );

        if (!eligibleToApplyLP) {
          errors.disableLoyaltyPoint = true;

          setTimeout(() => {
            setValues((prev) => ({
              ...prev,
              loyaltyPoint: '',
            }));
          }, 100);
        } else if (parseFloat(fieldValue) > parseFloat(total)) {
          errors.loyaltyPoint = `Loyalty Points can't be greater than $${parseFloat(
            total,
          ).toFixed(2)}`;
        } else if (fieldValue === '') {
          errors.loyaltyPoint = '';
        } else if (parseFloat(fieldValue) > loyaltyPoint) {
          errors.loyaltyPoint =
            "Points can't be greater than your loyalty points.";
        } else if (parseFloat(fieldValue) === 0) {
          errors.loyaltyPoint = "Points can't be 0.";
        } else {
          errors.loyaltyPoint = '';
        }
        break;
      default:
        break;
    }

    // console.log('fieldname: ', fieldname, ', fieldValue: ', fieldValue);

    setValues((previousState) => ({
      ...previousState,
      errors,
      [fieldname]: fieldValue,
    }));
    if (fieldValue === '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          coupon: '',
        },
      }));
      setCouponValue(true);
    }
  };
  const handleUseStoreCredit = () => {
    setValues((previousState) => ({
      ...previousState,
      usedStoreCredit: values.storeCredit,
    }));
    // setdiscountAmt(discountAmt + parseFloat(values.storeCredit));
  };

  // useEffect(() => {
  //   console.log('values: ', values);
  // }, [values]);

  useEffect(() => {
    if (
      cartData &&
      cartData.length > 0 &&
      values.loyaltyPoint &&
      values.usedLoyaltyPoint
    ) {
      const setLoyaltyPoint = () => {
        const usePointProductsTotal = cartData
          .filter((item) => item.product.use_point === '1')
          ?.reduce((acc, curr) => (acc += discountPrice(curr.price)), 0);

        // totalPrice
        const percentageDeducted = parseFloat(
          (values.loyaltyPoint / parseFloat(usePointProductsTotal)) * 100,
        ).toFixed(2);

        setpercentageDeducLoy(percentageDeducted);
      };

      setLoyaltyPoint();
    }
  }, [
    values.loyaltyPoint,
    cartData,
    percentageDeducCoupon,
    values.usedLoyaltyPoint,
  ]);

  const handleUseLoyaltyPoint = async () => {
    if (values.usedStoreCredit === '') {
      const eligibleToApplyLP = cartData.some(
        (item) => item?.product?.use_point === '1',
      );

      // handleInputLoyaltyPoint
      if (!eligibleToApplyLP) {
        setValues((prev) => ({
          ...prev,
          loyaltyPoint: '',
          errors: {
            ...prev.errors,
            disableLoyaltyPoint: true,
          },
        }));
        return;
      }

      setValues((previousState) => ({
        ...previousState,
        storeCredit: '',
        usedLoyaltyPoint: parseFloat(values.loyaltyPoint).toFixed(2),
      }));
      try {
        const data = {
          merchant_id: merchantId,
          customer_id: AuthUserState?.id,
          used_loyality_amt: values.loyaltyPoint,
        };

        const response = await axios.post(
          BASE_URL_SANDBOX + USED_LP_POINTS,
          data,
          { headers: { 'Content-Type': 'multipart/form-data' } },
        );

        if (response.data.status === 200) {
          setusedLoyaltyPointApiUserData(response.data);

          // setLoyaltyPoint();
          setdiscountAmt(discountAmt + parseFloat(values.loyaltyPoint));
        }
      } catch (error) {
        throw error;
      }
    }
    if (values.usedStoreCredit !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: {
          loyaltyPoint:
            'To use loyality points first remove store credit used amount.',
          storeCredit: '',
        },
      }));
    }
  };

  const handleRemoveStoreCredit = (setCouponValue) => {
    setValues((previousState) => ({
      ...previousState,
      usedStoreCredit: '',
      storeCredit: '',
      errors: { ...previousState.errors, coupon: '' },
    }));

    if (values.coupon !== '') {
      setCouponValue(true);
    }

    if (values.errors.loyaltyPoint !== '') {
      setValues((previousState) => ({
        ...previousState,
        errors: { ...previousState.errors, loyaltyPoint: '' },
      }));
    }
  };

  const handleRemoveLoyaltyPoint = (setCouponValue) => {
    setValues((previousState) => ({
      ...previousState,
      usedLoyaltyPoint: '',
      loyaltyPoint: '',
      errors: { ...previousState.errors, coupon: '' },
    }));
    if (values.coupon !== '') {
      setCouponValue(true);
    }

    setpercentageDeducLoy(0);
    setusedLoyaltyPointApiUserData('');
    setdiscountAmt(discountAmt - parseFloat(values.loyaltyPoint));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleApplyCoupon(e);
    }
  };
  return {
    handleInputChange,
    values,
    setValues,
    handleAddingTip,
    handleApplyCoupon,
    couponData,
    handleRemoveCoupon,
    handleKeyDown,
    orderSummaryData,
    handleStoreCredit,
    handleUseStoreCredit,
    handleRemoveStoreCredit,
    handleInputLoyaltyPoint,
    handleUseLoyaltyPoint,
    handleRemoveLoyaltyPoint,
    amountDeducCoupon,
    discountAmt,
  };
};

export default CouponTipSectionLogic;
